<template>
  <div class="flex flex-col bg-white h-screen w-full">
    <div class="flex-none h-16">
      <div class="border-2 flex flex-row h-full">
        <div class="flex-none grid place-items-center w-20">
          <app-link :to="{ name: 'dashboard' }" class="text-black"
            ><app-icon name="XIcon" class="w-5 cursor-pointer"></app-icon
          ></app-link>
        </div>
        <div class="flex-1 border-l-2 flex items-center px-10"></div>
      </div>
    </div>
    <div class="container h-full flex flex-col md:flex-row w-full">
      <div class="border-r border-gray-100 px-5 py-10">
        <div class="md:m-5">
        <app-button-menu
          class="
            md:mb-5 md:p-0 mb-3
            w-full
            md:w-auto md:mx-0
            text-xl
            font-semibold
            bg-white
            flex
            items-center
            md:hover:bg-white
            justify-between
          "
          @onMenuClick="toggleMenu"
        >
          <p class="text-black">
            {{ $t("business.settings.title") }}
          </p>
          <app-icon
            name="ChevronRightIcon"
            :class="[
              'h-6 w-6 text-black md:hidden transform',
              showMenu ? 'rotate-90' : 'rotate-0',
            ]"
          />
        </app-button-menu>
        <app-button-menu
          :class="[
            'w-full space-x-3 items-center font-medium border-l-2 md:flex transition-all',
            showMenu ? 'flex' : 'hidden',
          ]"
          v-for="menu in menu_items"
          v-bind:key="menu.title"
          :rounded="false"
          :fullWidth="true"
          @onMenuClick="onMenuClick(menu)"
          :active="isCurrentPath(menu.path)"
          :disabled="menu.disabled"
        >
          <p
            :class="[
              'text-left h-5 truncate ...',
              isCurrentPath(menu.path) ? 'text-primary' : 'text-black',
            ]"
          >
            {{ menu.title }}
          </p>
        </app-button-menu>
        </div>
      </div>
      <div class=" md:p-16 p-5 md:w-4/5 ">
        <router-view :key="$route.fullPath"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menu_items: [
        {
          id: 1,
          title: this.$t("business.company_logo.title"),
          path: "/business/settings/company-logo",
          disabled: false,
        },
        // Comment First because no api to integrate yet
        {
          id: 2,
          title: this.$t("business.settings.payout_option_title"),
          path: "/business/settings/payout-option",
          disabled: false,
        },
      ],
      showMenu: false,
    };
  },

  computed: {},

  beforeCreate() {},

  methods: {
    onMenuClick(menu) {
      this.$router.push({ path: menu.path });
    },

    isCurrentPath(path) {
      return this.$route.path == path;
    },

    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
  },
};
</script>